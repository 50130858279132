// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  home: $(".home-slider"),
  gallery: $(".gallerySlider")
};

sliders.home.slick({
  dots: false,
  autoplay: false
});

sliders.gallery.slick({
  dots: false,
  arrows: true,
  autoplay: false,
  slidesToShow: 2,
  slidesToScroll: 2,
  prevArrow: $('.prev'),
  nextArrow: $('.next'),
  responsive: [
    {
      breakpoint: 759,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
      }
    }
  ]
});
