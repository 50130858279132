import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";

window.isLoading = {
	count: null,
	update() {
		console.log(`this gets called and your value is ${this.state}`);
	},
	get state() {
		return this.count;
	},
	set state(state) {
		this.count = state;
		if (state) {
			const loading = document.querySelector(".loading");
			loading.classList.remove("loaded");
		} else {
			const loading = document.querySelector(".loading");
			loading.classList.add("loaded");
		}
		this.update(this.count);
	},
};

window.addEventListener("load", () => {
	new SiteHeader();
	new FloatLabels();
	window.isLoading.state = false;

	toggleMenu();

	if (scrollY > 0) {
		addSticky();
	}

	if (isMobile()) {
		closeMenu();
		addSticky();
	}
});

window.onresize = () => {
	if (isMobile()) {
		closeMenu();
		addSticky();
	}
};
